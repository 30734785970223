'use strict';
var util = require('../components/utils');
var _ = require('lodash');
/**
 * Method to append story rows
 * @param {Object} response placeholder for grid html content
 */
function appendBlogGrid(response) {
    $('.load-more, .load-all, .js-blog-story-attribute').remove();
    $('.story-col').last().after(response);
}

/**
 * Method to replace story rows
 * @param {Object} response placeholder for grid html content
 */
function replaceBlogGrid(response) {
    $('.blog-story-segment .blog-grid').empty().html(response);
}

module.exports = {
    loadCarousel: function () {
        $('.carousel').carousel();
    },
    /**
     * Event handler for load more button
     */
    loadMore: function () {
        $('.blog-story-segment').on('click', '.btn-load-more', function (e) {
            e.preventDefault();
            var url = $('.load-more').attr('action');
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    appendBlogGrid(response);
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
            return false;
        });
    },
    loadFiltered: function () {
        $('#filterStories').on('change', 'select', _.debounce(function (e) {
            e.preventDefault();
            var $parent = $(e.delegateTarget);
            var articleType = $parent.find('.js-filter-article-type').val();
            var sortType = $parent.find('.js-sort-article-type').val();
            var [sortBy, sortOrder] = sortType.split('_');
            var url = `${$('#filterStories').attr('action')}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
            if (articleType !== '') {
                url += `&aType=${articleType}`;
            }

            $.spinner().start();

            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    replaceBlogGrid(response);
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                    $.spinner().stop();
                }
            });

            return false;
        }, 500));
    },
    loadAllFeatured: function () {
        $('.blog-story-segment').on('click', '.btn-load-all-featured', function (e) {
            e.preventDefault();
            var url = $('.load-all-featured').attr('action');
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    replaceBlogGrid(response);
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });

            return false;
        });
    },
    loadAll: function () {
        $('.blog-story-segment').on('click', '.btn-load-all', function (e) {
            e.preventDefault();
            var url = $('.load-all').attr('action');
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    replaceBlogGrid(response);
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
            return false;
        });
    },
    ofsTabLinkClicked: function () {
        $('.js-blog-type-link').on('click', function () {
            var currentEle = $(this);
            var href = currentEle.attr('href');
            if (href) {
                window.location = href;
            }
            return false;
        });
    },
    loadPagination: function () {
        $('.blog-story-segment').on('click', '.js-blog-story-pagination', function (e) {
            e.preventDefault();
            if ($(this).hasClass('selected')) {
                return false;
            }
            var url = $(this).attr('href');
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'GET',
                success: function (response) {
                    replaceBlogGrid(response);
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
            return false;
        });
    },
    infiniteScroll: function () {
        // getting the hidden div, which is the placeholder for the next page
        $(document).bind('scroll', function () {
            var $loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
            // get url hidden in DOM
            var gridUrl = $loadingPlaceHolder.attr('data-grid-url');

            if ($loadingPlaceHolder.length === 1 && util.elementInViewport($loadingPlaceHolder.get(0), 250)) {
                // switch state to 'loading'
                // - switches state, so the above selector is only matching once
                // - shows loading indicator
                $loadingPlaceHolder.attr('data-loading-state', 'loading');
                $loadingPlaceHolder.addClass('infinite-scroll-loading');


                // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
                var fillEndlessScrollChunk = function (response) {
                    $loadingPlaceHolder.removeClass('infinite-scroll-loading');
                    $loadingPlaceHolder.attr('data-loading-state', 'loaded');
                    appendBlogGrid(response);
                };

                if ('sessionStorage' in window && sessionStorage['scroll-cache_' + gridUrl]) {
                    // if we hit the cache
                    fillEndlessScrollChunk(sessionStorage['scroll-cache_' + gridUrl]);
                } else {
                    $.spinner().start();
                    $.ajax({
                        type: 'GET',
                        url: gridUrl,
                        success: function (response) {
                            // put response into cache
                            try {
                                sessionStorage['scroll-cache_' + gridUrl] = response;
                            } catch (e) {
                                // nothing to catch in case of out of memory of session storage
                                // it will fall back to load via ajax
                            }
                            // update UI
                            fillEndlessScrollChunk(response);
                        },
                        complete: function () {
                            $.spinner().stop();
                        },
                        error: function () {
                            $.spinner().stop();
                        }
                    });
                }
            }
        });
    },
    webShare: function () {
        const $blogSocialShareMobile = $('.js-blog-social-share-mobile');
        const $blogSocialShareDesktop = $('.blog-social-container-desktop');

        // more information on WebShare API: https://developer.mozilla.org/en-US/docs/Web/API/Web_Share_API
        if (!navigator.share) {
            // fallback for browsers that do not support WebShare API, show Desktop social links
            $blogSocialShareMobile.addClass('d-none');
            $blogSocialShareDesktop.removeClass('d-none');
        } else {
            $blogSocialShareMobile.on('click', function (e) {
                e.preventDefault();
                navigator.share({
                    title: $blogSocialShareMobile.attr('data-title'),
                    url: $blogSocialShareMobile.attr('data-currenturl')
                }).catch(window.console.error);
            });
        }
    },
    copyClipboard: function () {
        // more information on Clipboard API: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard
        if (!navigator.clipboard) {
            // fallback for browsers that do not support clipboard
            $('.blog-copy-to-clipboard-share').addClass('d-none');
        } else {
            $('.js-blog-copy-to-clipboard-share-button').on('click', function (e) {
                e.preventDefault();
                const $copyBtn = $(this);

                // Get the current URL
                navigator.clipboard.writeText($copyBtn.data('currenturl'));

                // Alert that the URL has been copied
                $.alert({
                    content: $copyBtn.data('copy-success'),
                    theme: 'success',
                    timeToLive: 3000,
                    dismissible: false
                });
            });
        }
    }
};
